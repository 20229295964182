export const LOAD_USER = 'LOAD_USER';
export const CLEAN_USER = 'CLEAN_USER';

export function loadUserAction(user) {
  return {
    type: LOAD_USER,
    user
  };
}

export function cleanUserAction() {
  return {
    type: CLEAN_USER
  };
}


