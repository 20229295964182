import { combineReducers } from 'redux'
import watchGroupReducer from './watchGroup';
import userReducer from './user';
import sidebarReducer from './sidebar';
import newsReducer from './news';
import topicCategoryReducer from "./topicCategory";

const reducers = combineReducers({
  watchGroup : watchGroupReducer,
  user : userReducer,
  sidebar: sidebarReducer,
  news: newsReducer,
  category: topicCategoryReducer
});

export default reducers