import { getWebServiceUrl } from '../urlconstant';
import { getToken, cleanUserFromStorage } from '../auth/authApi';

export const register = async (user) => {
  const response = await fetch(`${getWebServiceUrl()}/account/register`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(user)
  });

  return response.json();
};

export const resetUserPassword = async (username) => {
  await fetch(`${getWebServiceUrl()}/account/reset/${username}`, {
    method: 'POST'
  });
}

export const setNewUserPassword = async (username, password, activateToken) => {
  await fetch(`${getWebServiceUrl()}/account/reset3/${username}/${activateToken}`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: password
  });
};

export const getTotalUserCount = async () => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/account/count`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });

    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
}

export const updateUser = async (user) => {
  try {
    await fetch(`${getWebServiceUrl()}/account?token=${getToken()}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(user)
    });
  }
  catch (err) {
    console.log(err);
  }
};

export const deleteUser = async (username) => {
  try {
    await fetch(`${getWebServiceUrl()}/account/${username}?token=${getToken()}`, {
      method: 'DELETE'
    });
  }
  catch (err) {
    console.log(err);
  }
};

export const getUser = async (username) => {
  const response = await fetch(`${getWebServiceUrl()}/account/${username}?token=${getToken()}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  });

  if (isResponseValid(response)) {
    return response.json();
  }

  return null;
};

export const getUserPublicInfo = async (username) => {
  const response = await fetch(`${getWebServiceUrl()}/account/public/${username}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  });

  if (isResponseValid(response)) {
    return response.json();
  }

  return null;
};

const isResponseValid = (resp) => {
  const status = resp.status;
  if (status > 209) {
    return false;
  } 

  return true;
}

export const getUsers = async () => {
  const response = await fetch(`${getWebServiceUrl()}/accounts?token=${getToken()}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  });
  
  if (isResponseValid(response)) {
    return response.json();
  }

  return null;
};

export const getActiveUsers = async () => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/accounts/active?token=${getToken()}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
};

export const getNotActiveUsers = async () => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/accounts/notActive?token=${getToken()}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
};

export const updateTopicsUserInfo = async (user) => {
  try {
    await fetch(`${getWebServiceUrl()}/account/updateTopics?token=${getToken()}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(user)
    });
  }
  catch (err) {
    console.log(err);
  }
};

export const setUserActiveOrgId = async (orgId) => {
  try {
    await fetch(`${getWebServiceUrl()}/account/activeOrgId/${orgId}?token=${getToken()}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
  }
  catch (err) {
    console.log(err);
  }
};

export const getActiveCaringUsers = async () => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/account/sampleCaring`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
};

export const getBestUsers = async () => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/account/bestAccounts`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
};