import {
  LOAD_NEWS,
  LOAD_NEWS_PAGEID,
  LOAD_NEWS_COUNT
} from '../actions/index';

const initState = {
  news: [],
  pageId: null,
  count: null
};

function newsReducer(state = initState, action) {
  switch (action.type) {
    case LOAD_NEWS: {
      return Object.assign({}, state, {
        news: action.news
      });
    }  
    case LOAD_NEWS_PAGEID: {
      return Object.assign({}, state, {
        pageId: action.pageId
      });
    }
    case LOAD_NEWS_COUNT: {
      return Object.assign({}, state, {
        count: action.count
      });
    }
    default: {
      return state;
    }
  }
}

export default newsReducer;