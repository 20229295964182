export const CLEAN_WATCHGROUPS = 'CLEAN_WATCHGROUPS';
export const LOAD_WATCHGROUPS = 'LOAD_WATCHGROUPS';
export const ADD_UPDATE_WATCHGROUP = 'ADD_UPDATE_WATCHGROUP';
export const GET_ALL_WATCHGROUPS = 'GET_ALL_WATCHGROUPS';
export const DEL_WATCHGROUP = 'DEL_WATCHGROUP';


export const CLEAN_WATCHORGGROUPS = 'CLEAN_WATCHORGGROUPS';
export const LOAD_WATCHORGGROUPS = 'LOAD_WATCHORGGROUPS';
export const ADD_UPDATE_WATCHORGGROUP = 'ADD_UPDATE_WATCHORGGROUP';
export const GET_ALL_WATCHORGGROUPS = 'GET_ALL_WATCHORGGROUPS';
export const DEL_WATCHORGGROUP = 'DEL_WATCHORGGROUP';


export function cleanWatchGroupsAction() {
  return {
    type: CLEAN_WATCHGROUPS
  };
}

export function loadWatchGroupsAction(watchGroups) {
  return {
    type: LOAD_WATCHGROUPS,
    watchGroups
  };
}

export function addUpdateWatchGroupAction(watchGroup) {
  return { 
    type: ADD_UPDATE_WATCHGROUP, 
    watchGroup 
  };
}

export function delWatchGroupAction(watchGroup) {
  return { 
    type: DEL_WATCHGROUP, 
    watchGroup 
  };
}

////////////////////////////////////////////////////////////////////////////////

export function cleanWatchOrgGroupsAction() {
  return {
    type: CLEAN_WATCHORGGROUPS
  };
}

export function loadWatchOrgGroupsAction(watchOrgGroups) {
  return {
    type: LOAD_WATCHORGGROUPS,
    watchOrgGroups
  };
}

export function addUpdateWatchOrgGroupAction(watchOrgGroup) {
  return { 
    type: ADD_UPDATE_WATCHORGGROUP, 
    watchOrgGroup 
  };
}

export function delWatchOrgGroupAction(watchOrgGroup) {
  return { 
    type: DEL_WATCHORGGROUP, 
    watchOrgGroup 
  };
}