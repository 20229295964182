import {
    LOAD_TOPIC_CATEGORY,
} from '../actions/index';

const initState = {
    category: {}
};

function topicCategoryReducer(state = initState, action) {
    switch (action.type) {
        case LOAD_TOPIC_CATEGORY: {
            return {
                category: action.category
            };
        }
        default: {
            return state;
        }
    }
}

export default topicCategoryReducer;