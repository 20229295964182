import {
  LOAD_USER,
  CLEAN_USER
} from '../actions/index';

const initState = {
  user: null
};

function userReducer(state = initState, action) {
  switch (action.type) {
    case LOAD_USER: {
      return {
        user: action.user
      };
    }
    case CLEAN_USER: {
      return {
        user: null
      };
    }
    default: {
      return state;
    }
  }
}

export default userReducer;