import { getToken } from '../auth/authApi';
import { getWebServiceUrl } from '../urlconstant';

/** 
TopicNode: {
  id: "",
  areaId: "",
  name: "",
  nodeType: "CATALOG",
  childIds: [], 
  summary: "",
  contentType: "HTML",
  content: "",
  tags: {},
  createTime: Date.now(),
  lastUpdateTime: Date.now()  
}

==> 

TopicNode: {
  id: "",
  name: "",
  nodeType: "CATALOG",
  childNodes: [], 
  summary: "",
  contentType: "HTML",
  content: "",
  tags: {},
  createTime: Date.now(),
  lastUpdateTime: Date.now()  
}

Topic: {
  id: "",
  name: "",
  description: "",
  author: "",
  orgId: "",
  domainId: "",
  createTime: Date.now(),
  lastUpdateTime: Date.now(),
  image: "",
  areas: []
}

TopicArea: {
  id: "",
  name: "",
  description: "",
  image: "",
  createTime: Date.now(),
  lastUpdateTIme: Date.now(),
  topNodes: []
}
*/

export const getTopicByName = async (name) => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topic?name=${name}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
}

export const getTopicById = async (id) => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topic/${id}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
}

export const getTotalTopicsCount = async () => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topics/count`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
}

export const getPersonalTopicsCount = async () => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topics/personal/count`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
}

export const getPublicTopicsCount = async (catId) => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topics/public/count?catId=${catId}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
}

export const getPublicTopicsByUsername = async (username) => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topics/public?username=${username}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
}

export const getNewestTopics = async () => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topics/public/newest/top`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
}

export const getPopularTopics = async () => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topics/public/popular/top`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
}

export const getNewUpdateTopics = async () => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topics/public/newupdate/top`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
}

export const getAllNewestTopicsInPage = async (pageId) => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topics/all/newest?pageId=${pageId}&token=${getToken()}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
}

export const getNewestTopicsInPage = async (catId, pageId) => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topics/public/newest?pageId=${pageId}&catId=${catId}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
}

export const getPopularTopicsInPage = async (catId, pageId) => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topics/public/popular?pageId=${pageId}&catId=${catId}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
}

export const getNewUpdateTopicsInPage = async (catId, pageId) => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topics/public/newupdate?pageId=${pageId}&catId=${catId}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
}

export const getDefaultTopics = async () => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topics/default`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
}

export const getAllTopics = async () => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topics/all?token=${getToken()}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
};

export const searchPublicTopics = async (keyword) => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topics/public/search?keyword=${keyword}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
};

export const getUserTopicsByOrgId = async (orgId) => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topics/?orgId=${orgId}&token=${getToken()}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
};

export const getMyTopicsByOrgIdAndDomainIdInPage = async (orgId, domainId, pageId) => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topics/my?orgId=${orgId}&domainId=${domainId}&pageId=${pageId}&token=${getToken()}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
};

export const getAllTopicsByOrgIdAndDomainIdInPage = async (orgId, domainId, pageId) => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topics/all?orgId=${orgId}&domainId=${domainId}&pageId=${pageId}&token=${getToken()}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
};

export const getSharedTopicsByOrgIdAndDomainIdInPage = async (orgId, domainId, pageId) => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topics/shared?orgId=${orgId}&domainId=${domainId}&pageId=${pageId}&token=${getToken()}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
};

export const getMyTopicsByOrgIdAndDomainIdCount = async (orgId, domainId) => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topics/my/count?orgId=${orgId}&domainId=${domainId}&token=${getToken()}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
};

export const getAllTopicsByOrgIdAndDomainIdCount = async (orgId, domainId) => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topics/all/count?orgId=${orgId}&domainId=${domainId}&token=${getToken()}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
};

export const getSharedTopicsByOrgIdAndDomainIdCount = async (orgId, domainId) => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topics/shared/count?orgId=${orgId}&domainId=${domainId}&token=${getToken()}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
};

export const getOrgPublicTopics = async () => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topics/orgPublic`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
};

export const getOrgPublicTopicsByOrgIdAndDomainIdInPage = async (orgId, domainId, pageId) => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topics/orgPublic?orgId=${orgId}&domainId=${domainId}&pageId=${pageId}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
};

export const getOrgPublicTopicsByOrgIdAndDomainIdCount = async (orgId, domainId) => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topics/orgPublic/count?orgId=${orgId}&domainId=${domainId}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
};

export const getAllTopicsByUsername = async (username) => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topics/all?username=${username}&token=${getToken()}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
};

export const getTopicsByUsername = async (username) => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topics?username=${username}&token=${getToken()}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
};

export const getTopicNewTopicNodes = async (id) => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topic/${id}/newTopicNodes/3`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
};

export const getAllTopicByIds = async (ids) => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topics/ids?token=${getToken()}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(ids)
    });

    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
};

export const getTopicPermission = async (id) => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topic/${id}/permission`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return response.text();
  }
  catch (err) {
    return console.log(err);
  }
};

export const validateTopicPermission = async (id, key) => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topic/${id}/validation?key=${key}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
};

export const getTopic = async (id) => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topic/${id}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip'
      }
    });
    return response ? response.json():null;
  }
  catch (err) {
    return console.log(err);
  }
};

export const getTopicWithKey = async (id, key) => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topicWithKey/${id}?key=${key}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip'
      }
    });

    return response ? response.json():null;
  }
  catch (err) {
    return console.log(err);
  }
};

export const ADD_LIKE = 'addLike';
export const DECR_LIKE = 'decrLike';
export const ADD_WATCH = 'addWatch';
export const DECR_WATCH = 'decrWatch';

export const modifyTopic = async (topicId, action) => {
  try {
    await fetch(`${getWebServiceUrl()}/topic/${topicId}?action=${action}&token=${getToken()}`, {
      method: 'POST'
    });
  }
  catch (err) {
    console.log(err);
  }
};

export const createUpdateTopic = async (topic) => {
  try {
    await fetch(`${getWebServiceUrl()}/topic?token=${getToken()}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(topic)
    });
  }
  catch (err) {
    console.log(err);
  }
};

export const deleteTopic = async (id) => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topic/${id}?token=${getToken()}`, {
      method: 'DELETE'
    });

    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
};

export const getAllTopicNodesByIds = async (topicNodeIds) => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topicNodes`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip'
      },
      body: JSON.stringify(topicNodeIds)
    });

    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
};

export const getAllTopicNodesSimple = async (topicNodeIds) => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topicNodes/simple`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(topicNodeIds)
    });

    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
};

export const getAllTopicNodesFull = async (topicNodeIds) => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topicNodes/full`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(topicNodeIds)
    });

    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
};

export const updateCreateTopicNodes = async (topicNodes) => {
  try {
    await fetch(`${getWebServiceUrl()}/topicNodes?token=${getToken()}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(topicNodes)
    });
  }
  catch (err) {
    console.log(err);
  }
};

export const createUpdateTopicNode = async (topicNode) => {
  try {
    await fetch(`${getWebServiceUrl()}/topicNode?token=${getToken()}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(topicNode)
    });
  }
  catch (err) {
    console.log(err);
  }
};

export const getTopicNodeById = async (id) => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topicNode/${id}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });

    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
}

export const delTopicNodeById = async (id) => {
  try {
    await fetch(`${getWebServiceUrl()}/topicNode/${id}?token=${getToken()}`, {
      method: 'DELETE'
    });
  }
  catch (err) {
    console.log(err);
  }
}

export const getAllTopicNodesInPage = async (pageId) => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topicNodes/all?pageId=${pageId}&token=${getToken()}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
};


export const getAllTopicNodesCount = async () => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/topicNodes/all/count?token=${getToken()}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
}


