import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Provider } from 'react-redux';
import { store } from './redux/store';
//import { createStore } from 'redux';
//import reducers from './reducers/index';

const meta = document.createElement('meta');
meta.content='same-origin';
meta.name='referrer';
document.getElementsByTagName('head')[0].appendChild(meta);

//let store = createStore(reducers)
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>, 
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

if (process.env.REACT_APP_MODE != 'dev') {
  /*
  document.oncontextmenu = function () {
    return false;
  };
  */  
}