import {
  CLEAN_WATCHGROUPS,
  LOAD_WATCHGROUPS,
  ADD_UPDATE_WATCHGROUP,
  CLEAN_WATCHORGGROUPS,
  LOAD_WATCHORGGROUPS,
  ADD_UPDATE_WATCHORGGROUP
} from '../actions/index';

const initState = {
  watchGroups: [
    /*
    {
      groupName: '',
      topicIds: []
    }
    */
  ],
  watchOrgGroups: [
    /*
    {
      groupName: '',
      orgIds: []
    }
    */
  ]
};

function watchGroupReducer(state = initState, action) {
  switch (action.type) {
    case LOAD_WATCHGROUPS: {
      return {
        watchGroups: action.watchGroups
      };
    }
    case CLEAN_WATCHGROUPS: {
      return {
        watchGroups: []
      };
    }
    case ADD_UPDATE_WATCHGROUP: {
      let curWg = state.watchGroups.find(wg => wg.groupName === action.watchGroup.groupName);
      if (!curWg) {
        return Object.assign({}, state, {
          watchGroups: [
            ...state.watchGroups,
            action.watchGroup
          ]
        });
      } else {
        let newWgs = state.watchGroups.map(wg => {
          if (wg.groupName === action.watchGroup.groupName) {
            return {
              groupName: wg.groupName,
              topicIds: [
                ...action.watchGroup.topicIds
              ]
            };
          }

          return wg;
        });

        return Object.assign({}, state, {
          watchGroups: newWgs
        });
      }
    }
    case LOAD_WATCHORGGROUPS: {
      return {
        watchOrgGroups: action.watchOrgGroups
      };
    }
    case CLEAN_WATCHORGGROUPS: {
      return {
        watchOrgGroups: []
      };
    }
    case ADD_UPDATE_WATCHORGGROUP: {
      if (state.watchOrgGroups) {
        let curWg = state.watchOrgGroups.find(wg => wg.groupName === action.watchOrgGroup.groupName);
        if (!curWg) {
          return Object.assign({}, state, {
            watchOrgGroups: [
              ...state.watchOrgGroups,
              action.watchOrgGroup
            ]
          });
        } else {
          let newWgs = state.watchOrgGroups.map(wg => {
            if (wg.groupName === action.watchOrgGroup.groupName) {
              return {
                groupName: wg.groupName,
                orgIds: [
                  ...action.watchOrgGroup.orgIds
                ]
              };
            }
  
            return wg;
          });
  
          return Object.assign({}, state, {
            watchOrgGroups: newWgs
          });
        }
      } else {
        return {
          watchOrgGroups: [
            action.watchOrgGroup
          ] 
        };
      }
    }
    default:
      return state;
  }
}

export default watchGroupReducer;