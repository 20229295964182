export const LOAD_NEWS = 'LOAD_NEWS';
export const LOAD_NEWS_PAGEID = 'LOAD_NEWS_PAGEID';
export const LOAD_NEWS_COUNT = 'LOAD_NEWS_COUNT';

export function loadNewsAction(news) {
  return {
    type: LOAD_NEWS,
    news
  };
}
export function loadNewsPageIdAction(pageId) {
  return {
    type: LOAD_NEWS_PAGEID,
    pageId
  };
}

export function loadNewsCountAction(count) {
  return {
    type: LOAD_NEWS_COUNT,
    count
  };
}