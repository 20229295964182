import {
  SHOW_SIDERBAR
} from '../actions/index';

const initState = {
  sidebarShow: false
};

function sidebarReducer(state = initState, action) {
  switch (action.type) {
    case SHOW_SIDERBAR: {
      return {
        sidebarShow: action.sidebarShow
      };
    }
    default: {
      return state;
    }
  }
}

export default sidebarReducer;