import { getWebServiceUrl } from '../urlconstant';
import { CACHE } from './tokenConstant';
import { getTopic } from '../topic/topicApi';

export const authenticate = async (user) => {
  try {
    const response = await fetch(`${getWebServiceUrl()}/authenticate`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(user)
    });

    return response.json();
  }
  catch (err) {
    return console.log(err);
  }
};

export const getToken = () => {
  let token = localStorage.getItem(CACHE.TOKEN);
  return "Bearer " + token;
};

export const getUsernameFromStorage = () => {
  let user = localStorage.getItem(CACHE.USERNAME);
  return user;
};

export const login = (token, username) => {
  localStorage.setItem(CACHE.TOKEN, token);
  localStorage.setItem(CACHE.USERNAME, username);
};

export const cleanUserFromStorage = () => {
  localStorage.removeItem(CACHE.TOKEN);
  localStorage.removeItem(CACHE.USERNAME);
};

export const logout = () => {
  //localStorage.removeItem(CACHE.TOKEN);
  //localStorage.removeItem(CACHE.USERNAME);
};

export const isLogin = () => {
  return localStorage.getItem(CACHE.TOKEN) && localStorage.getItem(CACHE.USERNAME);
}

export const addTopicKey = (topicId, key) => {
  let idIndex = "TOPIC_ID_" + topicId;
  localStorage.setItem(idIndex, key);
}

export const getTopicKey = (topicId) => {
  let idIndex = "TOPIC_ID_" + topicId;
  return localStorage.getItem(idIndex);
}

export const clearTopicKey = (topicId) => {
  let idIndex = "TOPIC_ID_" + topicId;
  localStorage.removeItem(idIndex);
} 

